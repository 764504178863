.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .thumbnail {
    height: 200px;
    background: url(../../images/thumbnail.gif) no-repeat center;
    background-size: 100%;
    width: 100%;
  }

  .section {
    display: flex;
    padding: 24px;
    box-sizing: border-box;
    flex-direction: column;

    &.last {
      padding-top: 40px;
      padding-bottom: 116px;
    }

    &.borderBottom {
      border-bottom: solid #f7f8fb 8px;
    }
  }

  .title {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 8px;

    span {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background: url(../../images/shield.svg) no-repeat center;
        top: 0;
        bottom: 0;
        margin: auto;
        right: -20px;
      }
    }
  }

  .describe {
    font-size: 14px;
    line-height: 24px;
    color: #272e40;
    margin-bottom: 28px;
  }
}

.checkDescribe {
  display: flex;
  margin-bottom: 20px;

  .check {
    width: 16px;
    height: 16px;
    position: relative;
    top: 2px;
    margin-right: 8px;
    background: url(../../images/ic-pass-b.svg) no-repeat center;
  }

  .describe {
    margin-bottom: 0;
  }
}

.link {
  margin-top: 8px;
  display: flex;
  text-decoration: none;
  width: 140px;
  height: 40px;
  border: solid #e9edf4 1px;
  font-size: 12px;
  line-height: 20px;
  color: #414d6b;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(204, 216, 232, 0.16);
  position: relative;
}

.highlight {
  color: #396eff;
  margin-bottom: 16px;
}

.hidden {
  color: #869ab7;
}

.question {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }

  .title {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  .answers {
    padding-left: 24px;

    .answer {
      display: flex;
      font-size: 14px;
      line-height: 1.71;
      margin-bottom: 8px;

      span {
        &:first-child {
          margin-right: 5px;
        }
      }
    }
  }
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16px;
  display: flex;
  width: 100%;
  box-sizing: border-box;

  .button {
    width: 100%;
    text-decoration: none;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    border: none;
    color: #fff;
    background: #396eff;
    font-size: 16px;
    box-shadow: 0 16px 32px 0 rgba(65, 77, 107, 0.16);
    position: relative;
  }
}

.iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}
